<template>
  <v-container fluid class="py-0">
    <v-row v-if="slots.title" align="center" justify="center" no-gutters class="mt-2">
      <v-col cols="12" class="text-center">
        <slot name="title" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-0">
      <v-col cols="11" sm="8" align-self="center">
        <v-item-group
          v-model="selectedIndex"
          class="white d-flex flex-column justify-center"
          mandatory
        >
          <v-item
            v-slot="{ isSelected, toggle }"
            v-for="(item, index) in visibleItems"
            :key="index"
          >
            <UiSelectButton :selected="isSelected" @click="toggle">
              <v-container class="pa-0">
                <v-row justify="center" align="center">
                  <v-col cols="auto">
                    <template v-if="typeof item === 'object' && type !== 'finance'">
                      {{ (item as any).yearId }} {{ (item as any).makeName }} {{ (item as any).modelName }}
                    </template>
                    <template v-else-if="typeof item === 'object' && type === 'finance'">
                      {{ (item as any).Vehicle.Year }} {{ (item as any).Vehicle.Make }} {{ (item as any).Vehicle.Model }}<br />
                      <span class="text-neutral40 font-italic font-weight-regular">VIN: {{ (item as any).Vehicle.VIN }}</span>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </v-col>
                  <v-col
                    v-if="isSelected"
                    cols="auto"
                    class="py-0 pr-4 pl-0 d-flex align-center"
                  >
                    <IconsCheckCircle
                      width="24"
                      height="24"
                      icon-color="rgb(var(--v-theme-secondary10))"
                      icon-opacity="0.8"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </UiSelectButton>
          </v-item>
        </v-item-group>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-messages
          :active="!!errorMessage"
          :messages="errorMessage"
          color="error"
          transition="none"
        ></v-messages>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

const slots = useSlots();

const props = defineProps({
  modelValue: {
    type: String as PropType<string | undefined>,
  },
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
  rules: {
    // type: Array,
    type: Array as PropType<((value: string) => boolean | string)[]>,
    default: () => [],
  },
  hideItems: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: "",
  }
});

const selectedIndex = ref();

// const errorMessage = ref(undefined);
const errorMessage = ref(undefined as undefined | string);

const visibleItems = computed(() => {
  return props.items.filter((item) => !props.hideItems.includes(item));
});

async function validate() {
  errorMessage.value = undefined;
  let isValid = true;
  for (let rule in props.rules) {
    let propValid = await props.rules[rule](props.modelValue ?? "");
    // function returns either true or a string error message
    if (propValid !== true) {
      errorMessage.value = typeof propValid === 'string' ? propValid : undefined as undefined | string;
      isValid = false;
      break;
    }
  }
  return isValid;
}
defineExpose({ validate });

watch(
  () => props.modelValue,
  (newValue) => {
    selectedIndex.value = props.items.findIndex((item) => item === newValue);
  }
);

const emit = defineEmits(["update:modelValue", "select"]);
watch(selectedIndex, (newValue) => {
  emit("update:modelValue", props.items[newValue]);
  emit("select", props.items[newValue]);
  nextTick(() => {
    validate();
  });
});
</script>
